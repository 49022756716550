import React from "react"
import { graphql } from "gatsby"
import { Typography, Container, Grid, Button, Box } from "@material-ui/core"
import _ from "lodash"
import Slider from "react-slick"
import { ArrowForward, ArrowBack } from "@material-ui/icons"

import theme from "../theme"
import LayoutComponent from "../components/layout.component"
import ArticleCardComponent from "../components/articlecard.component"
import CompanyCardComponent from "../components/companycard.component"
import SEO from "../components/seo.component"

const SlickArrowRight = props => {
  const { className, style, onClick } = props
  return (
    <ArrowForward
      fontSize="small"
      className={className}
      style={{
        ...style,
        display: "block",
        border: "1px solid black",
        color: "black",
        borderRadius: 20,
        marginRight: 7,
      }}
      onClick={onClick}
    />
  )
}

const SlickArrowLeft = props => {
  const { className, style, onClick } = props
  return (
    <ArrowBack
      fontSize="small"
      className={className}
      style={{
        ...style,
        display: "block",
        border: "1px solid black",
        color: "black",
        borderRadius: 20,
        marginLeft: 7,
      }}
      onClick={onClick}
    />
  )
}

const IndexPage = ({ data, location, navigate }) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <LayoutComponent>
      <SEO
        title="User Interface and User Experience Community for Designers"
        keywords="User Interface & User Experience community for Designers and Design enthusiasts around the Globe."
        description="User Interface & User Experience community for Designers and Design enthusiasts around the Globe."
        canonical={location.href}
      />
      <Box
        pt={10}
        pb={25}
        style={{ background: theme.palette.background.turquoise }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                component="h1"
                color="primary"
                gutterBottom
              >
                User Interface & User Experience community for Designers and
                Design enthusiasts around the Globe ground.
              </Typography>
              <Typography>
                Find great content and contemporaries from the design members
                who are willing to pass on their experience and know how of
                things that consistently evolve in digital space around us.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={10} mt={-25} pl={2} pr={2}>
        <Container maxWidth="xl">
          <Slider {...settings}>
            {_.map(data.allStrapiCompany.edges, ({ node }, i) => {
              return (
                <Box pl={1} pr={1} key={i}>
                  <CompanyCardComponent node={node} />
                </Box>
              )
            })}
          </Slider>
        </Container>
      </Box>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h5" component="h2">
                Featured Articles
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => navigate(`/latest`)}>
                View All
              </Button>
            </Grid>
          </Grid>
          <Box pt={5}>
            <Grid container spacing={3} direction="row">
              {_.map(
                _.slice(data.allStrapiArticle.edges, 0, 4),
                ({ node }, i) => {
                  return (
                    <Grid item xs={12} md={3} key={i}>
                      <ArticleCardComponent node={node} />
                    </Grid>
                  )
                }
              )}
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={3} direction="row">
            {_.map(
              _.slice(data.allStrapiArticle.edges, 0, 1),
              ({ node }, i) => {
                return (
                  <Grid item xs={12} md={6} key={i}>
                    <ArticleCardComponent node={node} excerpt={true} />
                  </Grid>
                )
              }
            )}
            <Grid item xs={12} md={6}>
              {_.map(
                _.slice(data.allStrapiArticle.edges, 2, 6),
                ({ node }, i) => {
                  return (
                    <ArticleCardComponent
                      node={node}
                      key={i}
                      horizontal={true}
                    />
                  )
                }
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container spacing={3} direction="row">
            {_.map(
              _.slice(data.allStrapiArticle.edges, 2, 11),
              ({ node }, i) => {
                return (
                  <Grid item xs={12} md={4}>
                    <ArticleCardComponent
                      node={node}
                      horizontal={true}
                      key={i}
                    />
                  </Grid>
                )
              }
            )}
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}

export default IndexPage

export const indexQuery = graphql`
  query IndexQuery {
    allStrapiArticle(sort: { fields: id, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          content
          excerpt
          createdAt
          picture {
            publicURL
            name
          }
          tags {
            id
            name
            slug
          }
          category {
            id
            name
            slug
          }
        }
      }
    }
    allStrapiCompany(sort: { fields: id, order: DESC }) {
      edges {
        node {
          id
          name
          founded_in
          excerpt
          description
          address
          hourly_rate
          type
          website
          office_locations
          location
          slug
          logo {
            publicURL
            name
          }
          cover {
            publicURL
            name
          }
          tags {
            id
            name
            slug
          }
        }
      }
    }
  }
`
